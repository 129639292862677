import React from "react"
import Layout from "../components/layout"
import Accordian from "../components/accordian"
import careersStyles from "./careers.module.css"
//import Content_surround from "../components/content_surround"
//import {Link} from "gatsby"

export default function Careers() {
  return (
    <Layout image="https://admhotels.com/images/join_adm.jpg" page_title="ADM Careers">
      <div style={{maxWidth:'800px',width:'100%'}} className={careersStyles.careers_layout}> 
        <div className={careersStyles.intro}>
          <h1 >Join ADM</h1>
        
          <p >Please review the following opportunites and follow the instructions within to Apply.</p>

        </div>

 {/*       <Accordian title="CEO"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for an experienced Chief Executive Officer or CEO to supervise and control all strategic and business aspects of the company. You will be the first in command in the company and responsible for giving the proper strategic direction as well as creating a vision for success.
          </p>
          <p>
          To thrive as a CEO you must be a prudent manager and an inspiring leader. The ideal candidate will have a business mindset and will be able to see the “big picture” in a variety of settings. They will take actions to enhance the company’s cash flow while keeping the human factor in perspective.
          </p>
          <p>
          The goal is to drive the company’s development and guide it towards long-term success.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
            <li>Develop high quality business strategies and plans ensuring their alignment with short-term and long-term objectives</li>
            <li>Lead and motivate subordinates to advance employee engagement develop a high performing managerial team</li>
            <li>Oversee all operations and business activities to ensure they produce the desired results and are consistent with the overall strategy and mission</li>
            <li>Make high-quality investing decisions to advance the business and increase profits</li>
            <li>Enforce adherence to legal guidelines and in-house policies to maintain the company’s legality and business ethics</li>
            <li>Review financial and non-financial reports to devise solutions or improvements</li>
            <li>Build trust relations with key partners and stakeholders and act as a point of contact for important shareholders</li>
            <li>Analyze problematic situations and occurrences and provide solutions to ensure company survival and growth</li>
            <li>Maintain a deep knowledge of the markets and industry of the company</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
            <li>Proven experience as CEO or in other managerial position</li>
            <li>Experience in developing profitable strategies and implementing vision</li>
            <li>Strong understanding of corporate finance and performance management principles</li>
            <li>Familiarity with diverse business functions such as marketing, PR, finance etc.</li>
            <li>In-depth knowledge of corporate governance and general management best practices</li>
            <li>An entrepreneurial mindset with outstanding organizational and leadership skills</li>
            <li>Analytical abilities and problem-solving skills</li>
            <li>Excellent communication and public speaking skills</li>
            <li>MSc/MA in business administration or relevant field</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$150,000+ annually</li>
            <li>401 K will be matched and One Million Us Dollars (50 % Term & 50 % whole life).</li>
            <li>Office time 8.00 am-4.pm (8 hours as required fulltime) and no overtime will be available to anyone.</li>
            <li>All employees should focus on their health and family after business hours.</li>
            <li>There will be dress code enforce, only dress Tie/suite for men and highly graceful ladies’ executive dress.</li>
            <li>Two weeks paid vacation will be available after completion 365 days with company.</li>
            <li>5 days will be sick leave available.</li>
            <li>Company car will be provided.</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />

        <Accordian title="COO"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for an experienced Chief Operating Officer or COO to oversee our organization’s ongoing operations and procedures. You will be the company’s second-in-command and responsible for the efficiency of business.
          </p>
          <p>
          The COO role is a key member of the senior management team, reporting only to the Chief Executive Officer (CEO). You’ll have to maintain control of diverse business operations, so we expect you to be an experienced and efficient leader. If you also have excellent people skills, business acumen and exemplary work ethics, we’d like to meet you.
          </p>
          <p>
          The goal of the COO position is to secure the functionality of business to drive extensive and sustainable growth.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
            <li>Design and implement business strategies, plans and procedures</li>
            <li>Set comprehensive goals for performance and growth</li>
            <li>Establish policies that promote company culture and vision</li>
            <li>Oversee daily operations of the company and the work of executives (IT, Marketing, Sales, Finance etc.)</li>
            <li>Lead employees to encourage maximum performance and dedication</li>
            <li>Evaluate performance by analyzing and interpreting data and metrics</li>
            <li>Write and submit reports to the CEO in all matters of importance</li>
            <li>Assist CEO in fundraising ventures</li>
            <li>Participate in expansion activities (investments, acquisitions, corporate alliances etc.)</li>
            <li>Manage relationships with partners/vendors</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
            <li>Proven experience as Chief Operating Office or relevant role</li>
            <li>Understanding of business functions such as HR, Finance, marketing etc.</li>
            <li>Demonstrable competency in strategic planning and business development</li>
            <li>Experience in fundraising will be a plus</li>
            <li>Working knowledge of data analysis and performance/operation metrics</li>
            <li>Working knowledge of IT/Business infrastructure and MS Office</li>
            <li>Outstanding organizational and leadership abilities</li>
            <li>Excellent interpersonal and public speaking skills</li>
            <li>Aptitude in decision-making and problem-solving</li>
            <li>BSc/BA in Business Administration or relevant field; MSc/MBA is a plus</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$150,000+ annually</li>
            <li>401 K will be matched and One Million Us Dollars (50 % Term & 50 % whole life).</li>
            <li>Office time 8.00 am-4.pm (8 hours as required fulltime) and no overtime will be available to anyone.</li>
            <li>All employees should focus on their health and family after business hours.</li>
            <li>There will be dress code enforce, only dress Tie/suite for men and highly graceful ladies’ executive dress.</li>
            <li>Two weeks paid vacation will be available after completion 365 days with company.</li>
            <li>5 days will be sick leave available.</li>
            <li>Company car will be provided.</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />
  */}

        <Accordian title="CFO"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for an experienced Chief Financial Officer or CFO to perform effective risk management and plan the organization’s financial strategy. It is a very important and complex role since an organization can only survive if it actively investigates and safeguards its finances.
          </p>
          <p>
          A CFO must be well-versed in all aspects of financial management ranging from simple accounting to broad investment and banking operations. The ideal candidate will be an excellent leader since all relative departments of the organization will be under their command.
          </p>
          <p>
          The goal is to protect the company’s revenues and profits to achieve full financial control and sustainable growth.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
            <li>Drive the company’s financial planning</li>
            <li>Perform risk management by analyzing the organization’s liabilities and investments</li>
            <li>Decide on investment strategies by considering cash and liquidity risks</li>
            <li>Control and evaluate the organization’s fundraising plans and capital structure</li>
            <li>Ensure cash flow is appropriate for the organization’s operations</li>
            <li>Supervise all finance personnel (controllers, treasurers etc.)</li>
            <li>Manage vendor relationships</li>
            <li>Prepare reliable current and forecasting reports</li>
            <li>Ensure compliance with the law and company’s policies</li>
            <li>Manage team of financial controllers and financial analysts.</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul>
          <li>Proven experience as CFO, finance officer or relevant role</li>
          <li>In depth knowledge of corporate financial law and risk management practices</li>
          <li>Excellent knowledge of data analysis and forecasting methods</li>
          <li>Proficient in the use of MS Office and financial management software (e.g. SAP)</li>
          <li>Ability to strategize and solve problems</li>
          <li>Strong leadership and organizational skills</li>
          <li>Excellent communication and people skills</li>
          <li>An analytical mind, comfortable with numbers</li>
          <li>CPA is a strong advantage</li>
          <li>BSc/BA in Accounting, Finance or relevant field; MSc/MBA is a plus</li>
        </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$150,000+ annually</li>
            <li>401 K will be matched and One Million Us Dollars (50 % Term & 50 % whole life).</li>
            <li>Office time 8.00 am-4.pm (8 hours as required fulltime) and no overtime will be available to anyone.</li>
            <li>All employees should focus on their health and family after business hours.</li>
            <li>There will be dress code enforce, only dress Tie/suite for men and highly graceful ladies’ executive dress.</li>
            <li>Two weeks paid vacation will be available after completion 365 days with company.</li>
            <li>5 days will be sick leave available.</li>
            <li>Company car will be provided.</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />

        <Accordian title="Business Development Director"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>

          <p>
          We are looking for an experienced Business Manager to lead and oversee the work of employees in our company. You will be responsible for ensuring the efficiency of business operations as well as setting strategic goals for the future.
          </p>

          <p>
          The ideal candidate will be well-versed in all matters business. They will be a competent leader able to provide guidance that enhances performance in a manner which incorporates the company’s vision and culture.
          </p>

          <p>
          The goal will be to ensure the profitability of our company’s activities to drive sustainable development and long-term success.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
          <li>Develop goals and objectives that tend to growth and prosperity</li>
          <li>Design and implement business plans and strategies to promote the attainment of goals</li>
          <li>Ensure that the company has the adequate and suitable resources to complete its activities (e.g. people, material, equipment etc.)</li>
          <li>Organize and coordinate operations in ways that ensure maximum productivity</li>
          <li>Supervise the work of employees and provide feedback and counsel to improve efficiency and effectiveness</li>
          <li>Maintain relationships with partners/vendors/suppliers</li>
          <li>Gather, analyze and interpret external and internal data and write reports</li>
          <li>Assess overall company performance against objectives</li>
          <li>Represent the company in events, conferences etc.</li>
          <li>Ensure adherence to legal rules and guidelines</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
          <li>Proven experience as business manager or relevant role</li>
          <li>Excellent organizational and leadership skills</li>
          <li>Outstanding communication and interpersonal abilities</li>
          <li>Thorough understanding of diverse business processes and strategy development</li>
          <li>Excellent knowledge of MS Office, databases and information systems</li>
          <li>Good understanding of research methods and data analysis techniques</li>
          <li>BSc/Ba in Business Management or relevant field; MSc/MA will be a plus</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$100,000+ annually</li>
            <li>401 K will be matched and One Million Us Dollars (50 % Term & 50 % whole life).</li>
            <li>Office time 8.00 am-4.pm (8 hours as required fulltime) and no overtime will be available to anyone.</li>
            <li>All employees should focus on their health and family after business hours.</li>
            <li>There will be dress code enforce, only dress Tie/suite for men and highly graceful ladies’ executive dress.</li>
            <li>Two weeks paid vacation will be available after completion 365 days with company.</li>
            <li>5 days will be sick leave available.</li>
            <li>Company car will be provided.</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />

        <Accordian title="Financial Controller, CPA"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for an experienced Financial Controller, or Comptroller, to undertake all aspects of financial management, including corporate accounting, regulatory and financial reporting, budget and forecasts preparation, as well as development of internal control policies and procedures. Finance Controller responsibilities will also include financial risk management.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
          <li>Manage all accounting operations including Billing, A/R, A/P, GL and Counsel, Cost Accounting, Inventory Accounting and Revenue Recognition</li>
          <li>Coordinate and direct the preparation of the budget and financial forecasts and report variances</li>
          <li>Prepare and publish timely monthly financial statements</li>
          <li>Coordinate the preparation of regulatory reporting</li>
          <li>Research technical accounting issues for compliance</li>
          <li>Support month-end and year-end close process</li>
          <li>Ensure quality control over financial transactions and financial reporting</li>
          <li>Manage and comply with local, state, and federal government reporting requirements and tax filings</li>
          <li>Develop and document business processes and accounting policies to maintain and strengthen internal controls</li>
          <li>Additional controller duties as necessary</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
          <li>Proven working experience as a Financial Controller</li>
          <li>5+ years of overall combined accounting and finance experience</li>
          <li>Advanced degree in Accounting</li>
          <li>CPA or CMA preferred</li>
          <li>Thorough knowledge of accounting principles and procedures</li>
          <li>Experience with creating financial statements</li>
          <li>Experience with general ledger functions and the month-end/year end close process</li>
          <li>Excellent accounting software user and administration skills</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$100,000+ annually</li>
            <li>401 K will be matched and One Million Us Dollars (50 % Term & 50 % whole life).</li>
            <li>Office time 8.00 am-4.pm (8 hours as required fulltime) and no overtime will be available to anyone.</li>
            <li>All employees should focus on their health and family after business hours.</li>
            <li>There will be dress code enforce, only dress Tie/suite for men and highly graceful ladies’ executive dress.</li>
            <li>Two weeks paid vacation will be available after completion 365 days with company.</li>
            <li>5 days will be sick leave available.</li>
            <li>Company car will be provided.</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />  

<Accordian title="Project Manager"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          As our project manager, your job will be to coordinate people and processes to ensure that our projects are delivered on time and produce the desired results. You will be the go-to person for everything involving a project’s organization and timeline.
          </p>
          <p>
          Specific project manager responsibilities include developing detailed project plans, ensuring resource availability and allocation and delivering every project on time within budget and scope.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul>
          <li>Coordinate internal resources and third parties/vendors for the flawless execution of projects</li>
          <li>Ensure that all projects are delivered on-time, within scope and within budget</li>
          <li>Developing project scopes and objectives, involving all relevant stakeholders and ensuring technical feasibility</li>
          <li>Ensure resource availability and allocation</li>
          <li>Develop a detailed project plan to track progress</li>
          <li>Use appropriate verification techniques to manage changes in project scope, schedule and costs</li>
          <li>Measure project performance using appropriate systems, tools and techniques</li>
          <li>Report and escalate to management as needed</li>
          <li>Manage the relationship with the client and all stakeholders</li>
          <li>Perform risk management to minimize project risks</li>
          <li>Establish and maintain relationships with third parties/vendors</li>
          <li>Create and maintain comprehensive project documentation</li>
        </ul>

          <p><b><u>Requirements</u></b></p>
          <ul>
          <li>Great educational background, preferably in the field of architecture or hotel development</li>
          <li>Proven working experience as a project administrator in the architecture or hotel development industry</li>
          <li>Solid architectural or hotel development background, with understanding or hands-on experience in architecture or hotel development</li>
          <li>Excellent client-facing and internal communication skills</li>
          <li>Excellent written and verbal communication skills</li>
          <li>Solid organizational skills including attention to detail and multi-tasking skills</li>
          <li>Strong working knowledge of Microsoft Office</li>
          <li>PMP certification is a plus</li>
        </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$100,000+ annually</li>
            <li>401 K will be matched and One Million Us Dollars (50 % Term & 50 % whole life).</li>
            <li>Office time 8.00 am-4.pm (8 hours as required fulltime) and no overtime will be available to anyone.</li>
            <li>All employees should focus on their health and family after business hours.</li>
            <li>There will be dress code enforce, only dress Tie/suite for men and highly graceful ladies’ executive dress.</li>
            <li>Two weeks paid vacation will be available after completion 365 days with company.</li>
            <li>5 days will be sick leave available.</li>
            <li>Company car will be provided.</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />          
{/*
        <Accordian title="Financial Director/Analyst, CPA"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for a Financial Analyst to provide accurate and data based information on company’s profitability, solvency, stability and liquidity. You will research and analyze financial information to help company make well informed decisions, write reports and monitor financial movements.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
          <li>Consolidate and analyze financial data (budgets, income statement forecasts etc) taking into account company’s goals and financial standing</li>
          <li>Provide creative alternatives and recommendations to reduce costs and improve financial performance</li>
          <li>Assemble and summarize data to structure sophisticated reports on financial status and risks</li>
          <li>Develop financial models, conduct benchmarking and process analysis</li>
          <li>Conduct business studies on past, future and comparative performance and develop forecast models</li>
          <li>Identify trends, advise company and recommend actions to a senior financial analyst based on sound analysis</li>
          <li>Track and determine financial status by analyzing actual results in comparison with forecasts</li>
          <li>Reconcile transactions by comparing and correcting data</li>
          <li>Gain and update job knowledge to remain informed about novelty in the field</li>
          <li>Consult with management to guide and influence long term and strategic decision making within the broadest scope</li>
          <li>Drive process improvement and policy development initiatives that impact the function</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
          <li>Proven working experience as a Finance Analyst</li>
          <li>Proficient in spreadsheets, databases, MS Office and financial software applications</li>
          <li>Hands on experience with statistical analysis and statistical packages</li>
          <li>Outstanding presentation, reporting and communication skills</li>
          <li>Proven knowledge of financial forecasting and diagnosis, corporate finance and information analysis</li>
          <li>Well informed in current financial subjects, accounting, tax laws, money market and business environments</li>
          <li>BS degree in Finance, Economics or related field</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$100,000+ annually</li>
            <li>Health, dental, vision insurance</li>
            <li>401 K will be offered</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />  
*/}
        <Accordian title="Risk Management Officer"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for a Risk Manager to advise us on various types of risks (business, financial, legal and security.) You will identify potential threats and create plans to prevent and mitigate problems.
          </p>
          <p>
          In this role, you should be highly perceptive and methodical. You should also have the ability to communicate effectively and present your plans in a convincing way. If you have experience in risk assessment and financial analysis, we’d like to meet you.
          </p>
          <p>
          Ultimately, you will ensure that our company secures its reputation and profitability by controlling risk.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
          <li>Conduct assessments to define and analyze possible risks</li>
          <li>Evaluate the gravity of each risk by considering its consequences</li>
          <li>Audit processes and procedures</li>
          <li>Develop risk management controls and systems</li>
          <li>Design processes to eliminate or mitigate potential risks</li>
          <li>Create contingency plans to manage crises</li>
          <li>Evaluate existing policies and procedures to find weaknesses</li>
          <li>Prepare reports and present recommendations</li>
          <li>Help implement solutions and plans</li>
          <li>Evaluate employees’ risk awareness and train them when necessary</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
          <li>Proven experience as a Risk Manager</li>
          <li>Knowledge of risk assessment and control</li>
          <li>Experience with auditing and reporting procedures</li>
          <li>Familiarity with industry compliance standards and regulations (e.g. Occupational Safety and Health Act)</li>
          <li>Strong computer and research skills; knowledge of analysis software is preferred (e.g. Statistical Analysis Software, or SAS)</li>
          <li>Analytical mind with problem-solving aptitude</li>
          <li>Excellent communication and presentation skills</li>
          <li>BSc/BA in Law, Business, Finance or a related field</li>
          <li>Professional Risk Manager (PRM) certification is a plus</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$50,000+ annually</li>
            <li>401 K will be matched and One Million Us Dollars (50 % Term & 50 % whole life).</li>
            <li>Office time 8.00 am-4.pm (8 hours as required fulltime) and no overtime will be available to anyone.</li>
            <li>All employees should focus on their health and family after business hours.</li>
            <li>There will be dress code enforce, only dress Tie/suite for men and highly graceful ladies’ executive dress.</li>
            <li>Two weeks paid vacation will be available after completion 365 days with company.</li>
            <li>5 days will be sick leave available.</li>
            <li>Company car will be provided.</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          /> 
{/*
        <Accordian title="Executive Assistant"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for an Executive Assistant to perform a variety of administrative tasks and support our company’s senior-level managers.
          </p>
          <p>
          Executive Assistant’s responsibilities include managing calendars, making travel arrangements and preparing expense reports. To be successful in this role, you should be well-organized, have great time management skills and be able to act without guidance.
          </p>
          <p>
          Ultimately, you will contribute to the efficiency of our business by providing personalized and timely support to executive members.  
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul>
          <li>Act as the point of contact among executives, employees, clients and other external partners</li>
          <li>Manage information flow in a timely and accurate manner</li>
          <li>Manage executives’ calendars and set up meetings</li>
          <li>Make travel and accommodation arrangements</li>
          <li>Rack daily expenses and prepare weekly, monthly or quarterly reports</li>
          <li>Oversee the performance of other clerical staff</li>
          <li>Act as an office manager by keeping up with office supply inventory</li>
          <li>Format information for internal and external communication – memos, emails, presentations, reports</li>
          <li>Take minutes during meetings</li>
          <li>Screen and direct phone calls and distribute correspondence</li>
          <li>Organize and maintain the office filing system</li>
        </ul>

          <p><b><u>Requirements</u></b></p>
          <ul>
          <li>Work experience as an Executive Assistant, Personal Assistant or similar role</li>
          <li>Excellent MS Office knowledge</li>
          <li>Outstanding organizational and time management skills</li>
          <li>Familiarity with office gadgets and applications (e.g. e-calendars and copy machines)</li>
          <li>Excellent verbal and written communications skills</li>
          <li>Discretion and confidentiality</li>
          <li>High School degree</li>
          <li>PA diploma or certification is a plus</li>
        </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$35,000+ annually</li>
            <li>Health, dental, vision insurance</li>
            <li>401 K will be offered</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          /> 

        <Accordian title="Executive Administrator/Office Manager"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for a reliable Office Administrator. They will undertake administrative tasks, ensuring the rest of the staff has adequate support to work efficiently.
          </p>
          <p>
          The tasks of the office administrator will include bookkeeping and mentoring office assistants. The ideal candidate will be competent in prioritizing and working with little supervision. They will be self-motivated and trustworthy.
          </p>
          <p>
          The office administrator ensures smooth running of our company’s offices and contributes in driving sustainable growth.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
          <li>Coordinate office activities and operations to secure efficiency and compliance to company policies</li>
          <li>Supervise administrative staff and divide responsibilities to ensure performance</li>
          <li>Manage agendas/travel arrangements/appointments etc. for the upper management</li>
          <li>Manage phone calls and correspondence (e-mail, letters, packages etc.)</li>
          <li>Support budgeting and bookkeeping procedures</li>
          <li>Create and update records and databases with personnel, financial and other data</li>
          <li>Track stocks of office supplies and place orders when necessary</li>
          <li>Submit timely reports and prepare presentations/proposals as assigned</li>
          <li>Assist colleagues whenever necessary</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
          <li>Proven experience as an office administrator, office assistant or relevant role</li>
          <li>Outstanding communication and interpersonal abilities</li>
          <li>Excellent organizational and leadership skills</li>
          <li>Familiarity with office management procedures and basic accounting principles</li>
          <li>Excellent knowledge of MS Office and office management software (ERP etc.)</li>
          <li>Qualifications in secretarial studies will be an advantage</li>
          <li>High school diploma; BSc/BA in office administration or relevant field is preferred</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$50,000+ annually</li>
            <li>Health, dental, vision insurance</li>
            <li>401 K will be offered</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />

        <Accordian title="Executive Secretary"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          We are looking for a competent Executive Secretary to support high-ranking officials in our company. You will be the one to organize and maintain the executive’s schedule and assist them by performing a variety of administrative tasks.
          </p>
          <p>
          Executive secretaries must be quick professionals with great time-management and multitasking abilities. It is with their diligence and competence in their work that executives can focus on their managerial responsibilities without worrying for other tasks.
          </p>
          <p>
          The goal is to contribute to the efficiency of the overall business by ensuring all assigned administrative duties are carried on timely and efficiently.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
          <li>Maintain executive’s agenda and assist in planning appointments, board meetings, conferences etc.</li>
          <li>Attend meetings and keep minutes</li>
          <li>Receive and screen phone calls and redirect them when appropriate</li>
          <li>Handle and prioritize all outgoing or incoming correspondence (e-mail, letters, packages etc.)</li>
          <li>Make travel arrangements for executives</li>
          <li>Handle confidential documents ensuring they remain secure</li>
          <li>Prepare invoices or financial statements and provide assistance in bookkeeping</li>
          <li>Monitor office supplies and negotiate terms with suppliers to ensure the most cost-effective orders</li>
          <li>Maintain electronic and paper records ensuring information is organized and easily accessible</li>
          <li>Conduct research and prepare presentations or reports as assigned</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
          <li>Proven experience as executive secretary or similar administrative role</li>
          <li>Proficient in MS Office and “back-office” software (e.g. ERP)</li>
          <li>In depth knowledge of office management and basic accounting procedures as well as technical vocabulary of relevant industry</li>
          <li>Familiarity with basic research methods and reporting techniques</li>
          <li>Excellent organizational and time-management skills</li>
          <li>Outstanding communication and negotiation abilities</li>
          <li>Integrity and confidentiality</li>
          <li>Degree in business administration or relative field</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$35,000+ annually</li>
            <li>Health, dental, vision insurance</li>
            <li>401 K will be offered</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          />                              

        <Accordian title="Office Secretary"
          content="
          <p><b><u>Location:</u></b> South Bend, IN</p>

          <p><b><u>Description:</u></b>  </p>
          <p>
          As a Secretary you will assume the duty of clerical and administrative support in order to optimize workflow procedures in the office.
          </p>
          <p>
          You will assist colleagues and executives by supporting them with planning and distributing information.
          </p>
          <p>
          You will be the point of reference for all queries, requests or issues and will be an integral part of the company’s workforce.
          </p>

          <p><b><u>Responsibilities</u></b></p>
          <ul >
          <li>Answer phone calls and redirect them when necessary</li>
          <li>Manage the daily/weekly/monthly agenda and arrange new meetings and appointments</li>
          <li>Prepare and disseminate correspondence, memos and forms</li>
          <li>File and update contact information of employees, customers, suppliers and external partners</li>
          <li>Support and facilitate the completion of regular reports</li>
          <li>Develop and maintain a filing system</li>
          <li>Check frequently the levels of office supplies and place appropriate orders</li>
          <li>Make travel arrangements</li>
          <li>Document expenses and hand in reports</li>
          <li>Undertake occasional receptionist duties</li>
          </ul>

          <p><b><u>Requirements</u></b></p>
          <ul >
          <li>Proven work experience as a Secretary or Administrative Assistant</li>
          <li>Familiarity with office organization and optimization techniques</li>
          <li>High degree of multi-tasking and time management capability</li>
          <li>Excellent written and verbal communication skills</li>
          <li>Integrity and professionalism</li>
          <li>Proficiency in MS Office</li>
          <li>High school diploma</li>
          </ul>

          <p><b><u>Compensation / Benefits</u></b></p>
          <ul >
            <li>$30,000+ annually</li>
            <li>Health, dental, vision insurance</li>
            <li>401 K will be offered</li>
          </ul>

          <div style='text-align:center;border:1px solid darkgrey'>
            <h2>Apply Now:</h2>
            <p>Send your resume to info@admhotels.com and tell us what role you are interested in.</p>
          </div>
          "
          /> 
*/}

{/*********************************************************************************************************/}


        

      </div>
    </Layout>



  ) 
}
