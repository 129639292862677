import React, {useState, useRef} from "react"
import accordianStyles from "./accordian.module.css"
//import Chevron from "./chevron"

export default function Accordian(props){
    const [setActive, setActiveState] = useState("")
    const [setHeight, setHeightState] = useState("0px")
    const [setRotate, setRotateState] = useState("accordion__icon")
    const [setButton, setButtonState] = useState("")

    const content = useRef(null);

    function toggleAccordian(){

        setActiveState(setActive === "" ? "active" : "")
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        )
        setRotateState(
            setActive === "active" ? "accordion__icon" : "rotate accordion__icon"
        )
        setButtonState(
            setActive === "" ? "active" : ""
        )
    }

    return(
        <div className="accordion__section">
            <button className={`${setButton} accordion`,accordianStyles.accordion2} onClick={toggleAccordian}>
                <p className="accordion__title">{props.title}</p>
                <svg
                    className={`${setRotate}`}
                    /*height={props.height}*/
                    width={10}
                    viewBox="0 0 266 438"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="m258.476 235.971-194.344 194.343c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901l154.021-154.746-154.021-154.745c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0l194.343 194.343c9.373 9.372 9.373 24.568.001 33.941z"
                    fill={"#777"}
                    />
                </svg>                
            </button>
            <div className="accordion__content"
                    ref={content}
                    style={{maxHeight: `${setHeight}`}}>

                <div className="accordion__text"
                        dangerouslySetInnerHTML={{ __html: props.content }}/>
            </div>
        </div>
    )
}